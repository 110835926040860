<template>
<v-app style="background-color: #f7f7f7">
  <v-container fluid style="padding: 20px;">
      <div class="tables-basic" id="inbox">
          <v-app-bar class="main-header"
                     height="64"
                     fixed
                     color='primary'
                     dark>
              <v-toolbar-title><v-img src="@/assets/sygnali_logo_450.png" contain style="width:20%"></v-img></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="logout()" style="margin-right:30px;">
                  <v-icon>mdi-logout</v-icon>
                  <div>Wyloguj</div>
              </v-btn>
          </v-app-bar>
          <v-row style="padding-top: 100px">
              <v-col cols="12">
                  <v-card>
                      <v-card-title class="pa-6 pb-6">
                          <p>SKRZYNKA ODBIORCZA SYGNALISTY</p>
                          <v-spacer></v-spacer>
                          <p style="margin-right:10px;">Wylogowanie za: {{formatTimeNumber(minutes)}}:{{formatTimeNumber(seconds)}}</p>
                          <v-btn @click="getNewToken()">
                              <v-icon color="blue">mdi-refresh</v-icon><div>Przedłuż</div>
                          </v-btn>
                      </v-card-title>
                  </v-card>
              </v-col>
          </v-row>
          <v-row>
              <v-col cols="6">
                  <v-card>
                      <v-card-title class="pa-6 pb-3">
                          <p>Informacje podstawowe zgłoszenia</p>
                          <v-spacer></v-spacer>
                      </v-card-title>
                      <v-card-text class="pa-3 pb-0" center>Identyfikator zgłoszenia: <span style="font-weight: bold;">{{signal.identifier}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Data wpływu: <span style="font-weight: bold;">{{signal.datetime}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Dni od zgłoszenia: <span style="font-weight: bold;">{{signal.days}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Etykieta: <span style="font-weight: bold;">{{signal.title}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Status: <span style="font-weight: bold;">{{signal.statusForReport}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Data zakończenia sprawy: <span style="font-weight: bold;">{{signal.archiveTime != null ? signal.archiveTime : '-'}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-1">Kategoria: <span style="font-weight: bold;">{{signal.category}}</span></v-card-text>
                  </v-card>
              </v-col>
              <v-col cols="6">
                  <v-card>
                      <v-card-title class="pa-6 pb-12">
                          <p>Akcje dla zgłoszenia</p>
                          <v-spacer></v-spacer>
                      </v-card-title>
                      <v-card-text class="pa-3 pb-2">
                          <v-card class="text-center">
                              <v-btn icon @click="showSignal()" :disabled="decryptSignal == true">
                                  <v-icon color="blue">mdi-key-variant</v-icon><div>Pokaż zgłoszenie</div>
                              </v-btn>
                          </v-card>
                      </v-card-text>
                      <v-card-text class="pa-3 pb-4" v-if="signal.status !== 3 && customerIsAvtive">
                          <v-card class="text-center">
                              <v-btn icon @click="showSendMessageBox()" v-if="!sendMessageFlag ">
                                  <v-icon color="green">mdi-email-outline</v-icon><div>NAPISZ WIADOMOŚĆ</div>
                              </v-btn>
                              <v-btn icon @click="hideSendMessageBox()" v-if="sendMessageFlag">
                                  <v-icon color="red">mdi-email-outline</v-icon><div>UKRYJ WIADOMOŚĆ</div>
                              </v-btn>
                          </v-card>
                      </v-card-text>
                      <v-card-text class="pa-3 pb-4" v-if="signal.status === 3 && customerIsAvtive">
                          <v-card class="text-center">
                              <v-card-text class="pa-3 pb-1"><span style="font-weight: bold;">TWOJE ZGŁOSZENIE ZOSTAŁO ZAMKNIĘTE</span></v-card-text>
                          </v-card>
                      </v-card-text>
                  </v-card>
              </v-col>
          </v-row>
          <v-row v-if="fileshowFlag">
              <v-col cols="12">
                  <v-card>
                      <v-card-title class="pa-6 pb-1">
                          <p>Załączniki</p>
                          <v-spacer></v-spacer>
                      </v-card-title>
                      <div v-if="signal.files.length > 0">
                          <v-card-text class="pa-6 pb-6">
                              <v-btn style="margin-left: 15px; margin-top:10px;" v-for="item in signal.files" :key="item.id" @click="getFile(item)">{{item.name}}</v-btn>
                              <v-spacer></v-spacer>
                          </v-card-text>
                      </div>
                      <div v-else>
                          <v-card-text class="pa-6 pb-6">
                              <div>
                                  Brak załączników
                              </div><v-spacer></v-spacer>
                          </v-card-text>
                      </div>
                  </v-card>
              </v-col>
          </v-row>
          <v-row v-if="sendMessageFlag">
              <v-col cols="12">
                  <v-card>
                      <v-card-title class="pa-6 pb-1">
                          <p>Wiadomość do odbiorcy zgłoszenia</p>
                          <v-spacer></v-spacer>
                      </v-card-title>
                      <div>
                          <v-card-text class="pa-3 pb-0">
                              <v-textarea v-model="textarea" solo auto-grow name="input-7-4" label="Treść wiadomości..."></v-textarea>
                          </v-card-text>
                      </div>
                  </v-card>
              </v-col>
          </v-row>
          <v-row v-if="sendMessageFlag" no-gutters class="align-start">
              <v-col cols="6">
                  <v-card-text class="pa-6 pb-0">
                      <v-card class="text-center pa-0 pb-0">
                          <v-btn icon @click="szyfruj()" :disabled="textarea.length == 0 || showLoaderReplyTosygnalist == true">
                              <v-progress-circular v-if="showLoaderReplyTosygnalist" style="margin-left: 20px;" :width="4" color="green" indeterminate></v-progress-circular>
                              <v-icon color="blue">mdi-cube-send</v-icon><div>WYŚLIJ</div>
                          </v-btn>
                      </v-card>
                  </v-card-text>
              </v-col>
              <v-col cols="6">
                  <v-card-text class="pa-6 pb-0">
                      <v-card class="text-center pa-0 pb-0">
                          <v-btn icon @click="addFile()" :disabled="showLoaderReplyTosygnalist == true">
                              <v-icon color="blue">mdi-file-import</v-icon><div>DODAJ załącznik</div>
                          </v-btn>
                      </v-card>
                  </v-card-text>
              </v-col>
              <v-col cols="12">
                  <v-card-text class="pa-6 pb-0">
                      <v-card>
                          <v-card-title>Lista załączników</v-card-title>
                          <div v-if="files != null && files.length > 0">
                              <v-card-text class="pa-2 pb-3" v-for="item in files" :key="item[0].name">
                                  {{item[0].name}} <v-btn icon @click="deleteFile(item)">
                                      <v-icon color="red">mdi-trash-can</v-icon>
                                  </v-btn>
                              </v-card-text>
                          </div>
                          <div v-else>
                              <v-card-text>Brak załączników</v-card-text>
                          </div>
                      </v-card>
                  </v-card-text>
              </v-col>
          </v-row>
          <v-row v-if="decryptSignal">
              <v-col cols="12">
                  <v-card>
                      <v-card-title class="pa-6 pb-3">
                          <p>Tablica konwersacji</p>
                          <v-spacer></v-spacer>
                          <v-btn icon @click="hide()" v-if="!hideMessages">
                              <v-icon color="red">mdi-eye-off-outline</v-icon><div>Ukryj wiadomości</div>
                          </v-btn>
                          <v-btn icon @click="show()" v-if="hideMessages">
                              <v-icon color="blue">mdi-eye-outline</v-icon><div>pokaż wiadomości</div>
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn icon @click="showFiles()" v-if="!fileshowFlag">
                              <v-icon color="blue">mdi-file-document</v-icon><div>Pokaż Załączniki</div>
                          </v-btn>
                          <v-btn icon @click="hideFiles()" v-if="fileshowFlag">
                              <v-icon color="grey">mdi-file-hidden</v-icon><div>ukryj Załączniki</div>
                          </v-btn>
                          <v-spacer></v-spacer>
                      </v-card-title>
                      <div v-if="!hideMessages">
                          <v-card-text class="pa-4 pb-3" v-for="item in messages" :key="item.id">
                              <v-card>
                                  <v-card-title class="pa-3 pb-0" style="margin-bottom: 15px">
                                      <p style="font-size: 0.8em;" v-if="item.adminName != null && item.adminName.length > 0">{{item.adminName}}</p>
                                      <p style="font-size: 0.8em;" v-else-if="item.sygnalistName != null && item.sygnalistName.length > 0">{{item.sygnalistName}}</p>
                                      <p style="font-size: 0.8em;" v-else>Sygnalista</p>
                                      <p style="margin-left: 15px; font-size: 0.8em;">{{item.datetime}}</p>
                                  </v-card-title>
                                  <v-card-text v-for="m in item.msg" :key="m.label" style="padding-top: 0px;">
                                      <div v-if="m.type == 1 || m.type == 2"> {{m.label}}: <b>{{m.value}}</b></div>
                                      <div v-else-if="m.type == 3"> {{m.label}}: <b>{{m.value == 'on' ? 'TAK' : 'NIE'}}</b></div>
                                      <div v-else> {{prepareLabelForSelect(m.label)}}: <b>{{m.value == '' ? 'Brak wyboru' : m.value}}</b></div>
                                  </v-card-text>
                              </v-card>
                          </v-card-text>
                      </div>
                  </v-card>
              </v-col>
          </v-row>


      </div>
  </v-container>
  </v-app>
</template>

<script>
    import Service from '@/services/Service'
    import CryptoJS from 'crypto-js'
export default {
  name: 'Signal',
  data() {
    return {
      newSignals: [],
      signals: [],
      signal: {},
      customerIsAvtive: false,
      showLoaderReplyTosygnalist: false,
      decryptSignal: false,
      hideMessages: false,
      sendMessageFlag: false,
      messages: [],
      textarea: '',
        password: '',
        fileshowFlag: false,
        files: [],
        countdown: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        set: null,
        setint: null,
        pk: '',
        pkid: 0,
        catid: 0
    }
  },
    created() {
        document.title = "Sygnali.pl - Skrzynka odbiorcza sygnalisty";
    },
  async mounted() {
      try {
          var temp = {
              identifier: this.$route.params.identifier,
              guid: this.$store.state.guid
          }
          var result = (await Service.getSpecificSignalForInbox(temp)).data;
          this.signal = result.signal,
          this.customerIsAvtive = result.customerIsActive;
          this.countdown = 900
      } catch (e) {
          this.$router.push({ name: 'InboxLogin' })
      }
  },
methods: {
    prepareLabelForSelect(item) {
        return item.split('|')[0];
    },
    right(str, chr) {
        return str.slice(str.length - chr, str.length);
    },
    b64decode(x) {
        var result = decodeURIComponent(escape(window.atob(x)));
        return result;
    },
    b64encode(x) {
        var result = btoa(x);
        return result;
    },
    async szyfruj() {

        try {
            this.showLoaderReplyTosygnalist = true;
            var b64 = '';

            var temp = {
                identifier: this.$route.params.identifier,
                guid: this.$store.state.guid
            };

            var result = (await Service.getSygnaliPublicKeyInbox(temp)).data;
            if ((Object.keys(result).length > 0)) {
                this.pk = result.key;
                this.pkid = result.id;
                var toencrypt = this.textarea;
                var c = CryptoJS.AES.encrypt(toencrypt, this.pk).toString();
                var w = [{ name: "reply_encrypted", value: c }];
                var w2 = JSON.stringify(w);
                b64 = this.b64encode(w2);
                setTimeout(() => { this.sendMessage(b64); }, 1000);
            }
            else {
                alert("Błąd podczas pobierania klucza zgłoszenia");
                this.showLoaderReplyTosygnalist = false;
            }
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString());
            this.showLoaderReplyTosygnalist = false;
        }
    },
    odszyfruj(k, s, result) {

        if (k != "0") {
            var x = this.decrypt(k, s);
            const arr = JSON.parse(x);
            var tempArr = [];
            for (var i = 0; i < arr.length; i++) {
                var tempId = arr[i].name.split("&")[0];
                var type = parseInt(arr[i].name.split("&").pop().split("#")[0]);

                //tymczasowo
                if (tempId.includes("_decrypted"))
                    tempId = "Pole";

                var temp = {
                    label: tempId,
                    value: arr[i].value,
                    type: type
                }

                tempArr.push(temp);
            }
            var msg = {
                adminName: result[0].adminName,
                sygnalistName: result[0].sygnalistName,
                datetime: result[0].datetime,
                msg: tempArr
            }

            this.messages.push(msg);
        }
        else {
            var temparr3 = [];
            var text = '';
            if (result[0].type == 2)
                text = 'Zgłoszenie telefoniczne';
            else if (result[0].type == 3)
                text = 'Zgłoszenie email';
            else
                text = 'Zgłoszenie';

            var ob = { label: text, type: 1, value: result[0].msg };
            temparr3.push(ob);

            var phoneMsg = {
                adminName: result[0].adminName,
                sygnalistName: result[0].sygnalistName,
                datetime: result[0].datetime,
                msg: temparr3
            };
            this.messages.push(phoneMsg);
        }

        // odszywrowanie reszty wiadomości 
        for (var j = 1; j < result.length; j++) {
            var x2 = this.decrypt(result[j].pkid, result[j].msg);
            const arr = JSON.parse(x2);
            var temparr2 = [];
            var obj = {
                label: 'Odpowiedź',
                value: arr[0].value,
                type: 1
            }
            temparr2.push(obj);
            var msg2 = {
                adminName: result[j].adminName,
                sygnalistName: result[j].sygnalistName,
                datetime: result[j].datetime,
                msg: temparr2
            }
            this.messages.push(msg2);
        }
    },
    decrypt(key, message) {
        var m = this.b64decode(message);
        const arr = JSON.parse(m);
        for (var item of arr) {
            var n = item.name;
            var v = item.value;
            if (this.right(n, 10) == '_encrypted' && v != '') {
                var bytes = CryptoJS.AES.decrypt(v, key);
                var result = bytes.toString(CryptoJS.enc.Utf8);
                item.value = result;
                item.name = item.name.replace("_encrypted", "_decrypted")
            }
        }
        return JSON.stringify(arr)
    },
      async showSignal() {
          try {
              var id = this.signal.identifier;
              var temp = {
                  identifier: id,
                  guid: this.$store.state.guid
              };

              var result = (await Service.showMessagesForInbox(temp)).data;
              if (Object.keys(result).length > 0) {
                  this.decryptSignal = true;
                  this.odszyfruj(result[0].pkid, result[0].msg, result);
                  this.catid = result[0].categoryId;
              }
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString())
          }
      },
      async getNewToken() {
          try {
              var id = this.signal.identifier;

              var temp = {
                  identifier: id,
                  guid: this.$store.state.guid
              };

              var result = (await Service.getNewToken(temp)).data;
              if (Object.keys(result).length > 0) {
                  this.$store.commit('changeGuid', result);
                  this.signal.validTo = 900;
                  this.countdown = 900;
              }
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString())
          }
      },
      countTimeForTimer(dateStart) {
          var date = new Date();
          var start = new Date(dateStart);
          if (start > date) {
              return Math.round((start - date) / 1000);
          } else {
              return 0;
          }
      },
      showFiles() {
          this.fileshowFlag = true;
      },
      hideFiles() {
          this.fileshowFlag = false;
      },
    hide() {
          this.hideMessages = true;
      },
      show() {
          this.hideMessages = false;
      },
      showSendMessageBox(){
          this.sendMessageFlag = true;
      },
    hideSendMessageBox() {
        this.textarea = '';
        this.files = [];
        this.sendMessageFlag = false;
      },
      async sendMessage(b64) {
          try {
              var temp = {
                  identifier: this.signal.identifier,
                  msg: b64,
                  guid: this.$store.state.guid,
                  pkid: parseInt(this.pkid),
                  catId: this.catid
              };

              var result = (await Service.sendReplyForInbox(temp)).data;
              if (Object.keys(result).length > 0) {
                  var temparr2 = [];
                  var obj = {
                      label: 'Odpowiedź',
                      value: this.textarea,
                      type: 1
                  }
                  temparr2.push(obj);
                  var msg2 = {
                      adminName: result.adminName,
                      sygnalistName: result.sygnalistName,
                      datetime: result.datetime,
                      msg: temparr2
                  }
                  this.messages.push(msg2);

                  this.textarea = '';
                  this.sendMessageFlag = false;
                  this.pk = '';
                  this.pkid = 0;
                  this.catid = 0;

                  if (this.files.length > 0) {
                      let formData = new FormData();
                      for (var i = 0; i < this.files.length; i++) {
                          let file = this.files[i][0];
                          formData.append('files[' + i + ']', file);
                      }
                      formData.append("identifier", this.signal.identifier);
                      formData.append("guid", this.$store.state.guid);

                      var res = (await Service.saveFileForSignalInbox(formData)).data;
                      if (Object.keys(res).length > 0) {
                          if (this.signal.files == null)
                              this.signal.files = [];

                          for (var j = 0; j < res.length; j++) {
                              this.signal.files.push(res[j]);
                          }

                          this.files = [];
                      }
                  }
              }
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString())
          }
          finally {
              this.showLoaderReplyTosygnalist = false;
          }
      },
      checkMimeType(type) {
          if (type != "application/pdf" && type != "image/jpeg" && type != "image/jpg" &&
              type != "image/png" && type != "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
              type != "text/csv" &&
              type != "audio/mpeg" &&
              type != "audio/x-m4a" &&
              type != "application/vnd.ms-excel" &&
              type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
              type != "text/plain") {
              return false;
          } else {
              return true;
          }
      },
      async getFile(item) {
          try {
              var id = this.signal.identifier;
              var temp = {
                  identifier: id,
                  guid: this.$store.state.guid,
                  fileId: item.id
              };
              var result = (await Service.downloadFileForInbox(temp));

              if (result.status == 200) {
                  const contentType = result.headers['content-type'];
                  var a = document.createElement('a');
                  var blob = new Blob([result.data], { 'type': contentType });
                  a.href = window.URL.createObjectURL(blob);
                  a.download = name;
                  a.click();
              }
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString());
          }
      },
      async logout() {
          try {
              var id = this.signal.identifier;
              var temp = {
                  identifier: id,
                  guid: this.$store.state.guid,
              };
              var result = (await Service.logoutInbox(temp));

              if (result.status == 200) {
                  this.$store.commit('changeGuid', '');
                  this.$router.push({ name: 'InboxLogin' });
              }

          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString());
          }
      },
      addFile() {
          let input = document.createElement('input');
          input.type = 'file';
          input.accept = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.png,.jpg,.jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel, .csv, audio/mpeg, audio/x-m4a';
          input.multiple = false;
          input.click();
          input.addEventListener('change', (event) => {
              if (event.target.files[0].size <= 30000000) {
                  if (this.checkMimeType(event.target.files[0].type)) {
                      if (this.files.length > 0) {
                          for (var i = 0; i < this.files.length; i++) {
                              if (this.files[i][0].name == event.target.files[0].name)
                                  return;
                          }

                          var tempFileSize = 0;
                          for (var j = 0; j < this.files.length; j++) {
                              tempFileSize += this.files[j][0].size;
                          }
                          tempFileSize += event.target.files[0].size;
                          if (tempFileSize >= 30000000) {
                              alert("Zbyt duży rozmiar pliku! Suma załączników nie może przekraczać 30MB");
                              return;
                          }

                          this.files.push(event.target.files);
                      }
                      else {
                          this.files.push(event.target.files);
                      }
                  }
                  else {
                      alert("Nieobsługiwany typ pliku. Pliki obsługiwane to: DOC,DOCX,PDF,JPG,JPEG,PNG,XLS,XLSX,CSV,MP3,M4A")
                  }
              }
              else {
                  alert("Zbyt duży rozmiar pliku! Suma załączników nie może przekraczać 30MB");
              }
          });
      },
      deleteFile(item) {
          if (this.files.length > 0) {
              for (var i = 0; i < this.files.length; i++) {
                  if (this.files[i][0].name == item[0].name) {
                      var index = this.files.indexOf(this.files[i])
                      this.files.splice(index, 1);
                  }
              }
          }
      },
      formatTimeNumber(n) {
          return n > 9 ? n.toString() : '0' + n.toString();
      },
    },
    watch: {
        countdown: {
            handler(value) {
                if (value > 0) {
                    if (value == 900) {
                        clearTimeout(this.set);
                        this.countdown = value;
                    }
                    this.set = setTimeout(() => {
                        this.countdown--;
                        this.days = Math.floor(this.countdown / (3600 * 24));
                        this.hours = Math.floor(this.countdown % (3600 * 24) / 3600);
                        this.minutes = Math.floor(this.countdown % 3600 / 60);
                        this.seconds = Math.floor(this.countdown % 60);
                    }, 1000);
                } else {
                    this.setint = setTimeout(() => {
                        if (this.countdown == 0) {
                            this.countdown = 0;
                            this.days = 0;
                            this.hours = 0;
                            this.minutes = 0;
                            this.seconds = 0;
                            this.logout();
                        }
                        
                    }, 1050);
                }
            },
            immediate: true
        },
    }
}

</script>

<style scope>
    div#inbox {
        max-width: 1280px;
        margin: auto;
    }
</style>
