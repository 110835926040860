<template>
    <v-container fluid>
        <div class="tables-basic">
            <h1 class="page-title mt-10 mb-6">Zgłoszenie</h1>

            <ErrorSubscription :isCustomerActive="isCustomerActive()" />

            <v-row v-if="isCustomerActive()">
                <v-col cols="6">
                    <v-card>
                        <v-card-title class="pa-6 pb-3">
                            <p>Informacje podstawowe zgłoszenia</p>
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text class="pa-3 pb-2" center>Identyfikator zgłoszenia: <span style="font-weight: bold;">{{signal.identifier}}</span></v-card-text>
                        <v-card-text class="pa-3 pb-2">Data wpływu: <span style="font-weight: bold;">{{signal.datetime}}</span></v-card-text>
                        <v-card-text class="pa-3 pb-2">Dni od zgłoszenia: <span style="font-weight: bold;">{{signal.days}}</span></v-card-text>
                        <v-card-text class="pa-3 pb-2">Etykieta: <span style="font-weight: bold;">{{signal.title}}</span></v-card-text>
                        <v-card-text class="pa-3 pb-2">Status: <span style="font-weight: bold;">{{signal.statusForReport}}</span></v-card-text>
                        <v-card-text class="pa-3 pb-2">Data zakończenia sprawy: <span style="font-weight: bold;">{{signal.archiveTime != null ? signal.archiveTime : '-'}}</span></v-card-text>
                        <v-card-text class="pa-0 pb-6">
                            <v-col class="d-flex" sm="6" cols="12">
                                Kategoria:
                                <v-select v-model="selectedCategory" :items="cat" item-text="catName" item-value="catId" @change="moveSygnalToCategory($event)" dense style="margin-left:10px;"></v-select>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card>
                        <v-card-title class="pa-2 pb-0">
                            <p>Akcje dla zgłoszenia</p>
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text class="pa-3 pb-0">
                            <v-card class="text-center">
                                <v-btn icon @click="showNotes()">
                                    <v-icon color="orange">mdi-pen</v-icon><div>Działania następcze</div>
                                </v-btn>
                            </v-card>
                        </v-card-text>
                        <v-card-text class="pa-3 pb-0">
                            <v-card class="text-center">
                                <v-btn icon @click="showPropertyDamageInfo()">
                                    <v-icon color="red">mdi-bank-outline</v-icon><div>Informacje o szkodzie majątkowej</div>
                                </v-btn>
                            </v-card>
                        </v-card-text>
                        <v-card-text class="pa-3 pb-0">
                            <v-card class="text-center">
                                <v-btn icon @click="showSignal()" :disabled="decryptSignal == true">
                                    <v-icon color="blue">mdi-key-variant</v-icon><div>Zobacz zgłoszenie</div>
                                </v-btn>
                            </v-card>
                        </v-card-text>
                        <v-card-text class="pa-3 pb-0">
                            <v-card class="text-center">
                                <v-btn icon @click="showPrivateFiles()">
                                    <v-icon color="green">mdi-file-import</v-icon><div>Załączniki prywatne</div>
                                </v-btn>
                            </v-card>
                        </v-card-text>
                        <v-card-text class="pa-3 pb-0">
                            <v-card class="text-center">
                                <v-btn icon @click="showLogsForSignal()">
                                    <v-icon color="silver">mdi-account-search</v-icon><div>Pokaż logi dla zgłoszenia</div>
                                </v-btn>
                            </v-card>
                        </v-card-text>
                        <v-card-text class="pa-3 pb-0">
                            <v-card class="text-center" v-if="canArchivize">
                                <v-btn icon @click="addToArchiv()" :disabled="signal.status == 3 || signal.status == 1">
                                    <v-icon color="brown">mdi-archive</v-icon><div>Zarchiwizuj Zgłoszenie</div>
                                </v-btn>
                            </v-card>
                            <v-card class="text-center" v-else>
                                <v-card-text class="pa-3 pb-1"><span style="font-weight: bold;">BRAK UPRAWNIEŃ DO ARCHIWIZACJI</span></v-card-text>
                            </v-card>
                        </v-card-text>
                        <v-card-text class="pa-3 pb-2" v-if="signal.status !== 3 && signal.canResponse">
                            <v-card class="text-center">
                                <v-btn icon @click="showSendMessageBox()">
                                    <v-icon color="green">mdi-email-outline</v-icon><div>ODPOWIEDZ SYGNALIŚCIE</div>
                                </v-btn>
                            </v-card>
                        </v-card-text>
                        <v-card-text class="pa-3 pb-2" v-else>
                            <v-card class="text-center">
                                <v-card-text v-if="signal.status == 3" class="pa-3 pb-1"><span style="font-weight: bold;">ZGŁOSZENIE ZOSTAŁO ZAMKNIĘTE</span></v-card-text>
                                <v-card-text v-if="!signal.canResponse" class="pa-3 pb-1"><span style="font-weight: bold;">BRAK UPRAWNIEŃ DO ODPOWIADANIA SYGNALIŚCIE</span></v-card-text>
                            </v-card>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="showPrivateFilesFlag == true && isCustomerActive()">
                <v-col cols="12">
                    <v-card>
                        <v-card-title class="pa-6 pb-1">
                            <p>Załączniki prywatne</p>
                            <v-spacer></v-spacer>
                            <v-btn @click="addPrivateFile()" style="margin-left:50px;">
                                <v-icon color="green">mdi-file-import</v-icon><div>DODAJ załącznik prywatny</div>
                            </v-btn>
                        </v-card-title>
                        <v-progress-circular v-if="showLoader" style="margin-left: 20px;" :width="4" color="green" indeterminate></v-progress-circular>
                        <div v-if="privateFiles.length > 0">
                            <v-card-text class="pa-6 pb-6">
                                <span v-for="item in privateFiles" :key="item.id">
                                    <div>
                                        <v-btn style="margin-left: 15px; margin-top: 10px; max-height: 30px;" @click="getPrivateFile(item)"> {{item.name}} </v-btn>
                                        <v-btn style="margin-top: 10px; max-height: 30px;" @click="deletePrivateFile(item)"><v-icon color="red">mdi-delete</v-icon></v-btn>
                                    </div>
                                </span>

                            </v-card-text>
                        </div>
                        <div v-else>
                            <v-card-text class="pa-6 pb-6">
                                <div>
                                    Brak załączników prywatnych
                                </div><v-spacer></v-spacer>
                            </v-card-text>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="fileshowFlag && isCustomerActive()">
                <v-col cols="12">
                    <v-card>
                        <v-card-title class="pa-6 pb-1">
                            <p>Załączniki dołączone do zgłoszenia (widoczne dla sygnalisty)</p>
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <div v-if="signal.files.length > 0">
                            <v-card-text class="pa-6 pb-6">
                                <v-btn style="margin-left: 15px; margin-top: 10px;" v-for="item in signal.files" :key="item.id" @click="getFile(item)">{{item.name}}</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-text>
                        </div>
                        <div v-else>
                            <v-card-text class="pa-6 pb-6">
                                <div>
                                    Brak załączników
                                </div><v-spacer></v-spacer>
                            </v-card-text>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="sendMessageFlag && isCustomerActive()">
                <v-col cols="12">
                    <v-card class="pa-3 pb-3">
                        <v-card-title class="pa-6 pb-1">
                            <p>Wiadomość do sygnalisty</p>
                            <v-spacer></v-spacer>
                            <v-btn @click="hideSendMessageBox()" style="margin-left:50px;">Ukryj</v-btn>
                        </v-card-title>
                        <div>
                            <v-card-text class="pa-3 pb-0">
                                <v-textarea v-model="textarea" auto-grow outlined name="input-7-4" label="Treść wiadomości..."></v-textarea>
                            </v-card-text>
                        </div>
                        <v-row>
                            <v-col cols="6">
                                <v-card-text class="pa-2 pb-0">
                                    <v-card class="text-center pa-0 pb-0">
                                        <v-btn icon @click="szyfruj()" :disabled="textarea.length == 0 || showLoaderReplyTosygnalist == true">
                                            <v-progress-circular v-if="showLoaderReplyTosygnalist" style="margin-left: 20px;" :width="4" color="green" indeterminate></v-progress-circular>
                                            <v-icon color="blue">mdi-cube-send</v-icon><div>WYŚLIJ</div>
                                        </v-btn>
                                    </v-card>
                                </v-card-text>
                            </v-col>
                            <v-col cols="6">
                                <v-card-text class="pa-3 pb-0">
                                    <v-card class="text-center pa-0 pb-0">
                                        <v-btn icon @click="addFile()" :disabled="showLoaderReplyTosygnalist == true">
                                            <v-icon color="blue">mdi-file-import</v-icon><div>DODAJ załącznik</div>
                                        </v-btn>
                                    </v-card>
                                </v-card-text>
                            </v-col>
                        </v-row>
                        <div>
                            <v-card-text class="pa-3 pb-0">
                                <v-card-title>Lista załączników</v-card-title>
                                <div v-if="files != null && files.length > 0">
                                    <v-card-text class="pa-2 pb-3" v-for="item in files" :key="item[0].name">
                                        {{item[0].name}} <v-btn icon @click="deleteFile(item)">
                                            <v-icon color="red">mdi-trash-can</v-icon>
                                        </v-btn>
                                    </v-card-text>
                                </div>
                                <div v-else>
                                    <v-card-text>Brak załączników</v-card-text>
                                </div>
                            </v-card-text>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="decryptSignal && isCustomerActive()">
                <v-col cols="12">
                    <v-card>
                        <v-card-title class="pa-6 pb-3">
                            <p>Tablica konwersacji</p>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="hide()" v-if="!hideMessages">
                                <v-icon color="red">mdi-eye-off-outline</v-icon><div>Ukryj wiadomości</div>
                            </v-btn>
                            <v-btn icon @click="show()" v-if="hideMessages">
                                <v-icon color="blue">mdi-eye-outline</v-icon><div>pokaż wiadomości</div>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="showFiles()" v-if="!fileshowFlag">
                                <v-icon color="blue">mdi-file-document</v-icon><div>Pokaż Załączniki</div>
                            </v-btn>
                            <v-btn icon @click="hideFiles()" v-if="fileshowFlag">
                                <v-icon color="grey">mdi-file-hidden</v-icon><div>ukryj Załączniki</div>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <div v-if="!hideMessages">
                            <v-card-text class="pa-4 pb-3" v-for="item in messages" :key="item.id">
                                <v-card>
                                    <v-card-title class="pa-3 pb-0" style="margin-bottom: 15px">
                                        <p style="font-size: 0.8em;" v-if="item.adminName != null && item.adminName.length > 0">Administrator: {{item.adminName}}</p>
                                        <p style="font-size: 0.8em;" v-else-if="item.sygnalistName != null && item.sygnalistName.length > 0">Sygnalista: {{item.sygnalistName}} {{item.sygnalistEmail != null && item.sygnalistEmail.length > 0 ? '(e-mail: ' + item.sygnalistEmail + ')' : ''}} {{item.sygnalistPhone != null && item.sygnalistPhone.length > 0 ? '(telefon: ' + item.sygnalistPhone + ')' : ''}}</p>
                                        <p style="font-size: 0.8em;" v-else>Sygnalista</p>
                                        <p style="margin-left: 15px; font-size: 0.8em;">{{item.datetime}}</p>
                                    </v-card-title>
                                    <v-card-text v-for="m in item.msg" :key="m.label" style="padding-top: 0px;">
                                        <div v-if="m.type == 1 || m.type == 2"> {{m.label}}: <b>{{m.value}}</b></div>
                                        <div v-else-if="m.type == 3"> {{m.label}}: <b>{{m.value == 'on' ? 'TAK' : 'NIE'}}</b></div>
                                        <div v-else> {{prepareLabelForSelect(m.label)}}: <b>{{m.value == '' ? 'Brak wyboru' : m.value}}</b></div>
                                    </v-card-text>
                                </v-card>
                            </v-card-text>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="showNotesModal && isCustomerActive()">
                <v-col cols="12">
                    <v-card>
                        <v-card-title class="pa-6 pb-1">
                            <p>Działania następcze</p>
                            <v-spacer></v-spacer>
                            <v-btn @click="closeModal()" style="margin-left:50px;">Ukryj</v-btn>
                        </v-card-title>
                        <div v-if="notes.length > 0">
                            <v-card-text class="pa-4 pb-1" v-for="item in notes" :key="item.id">
                                <v-card>
                                    <v-card-title class="pa-3 pb-0" style="margin-bottom: 15px">
                                        <p style="font-size: 0.8em;">{{item.adminName}}</p>
                                        <p style="margin-left: 15px; font-size: 0.8em;">{{formatDateToDisplay(item.date)}} {{item.showInReport == true ? '(wpis widoczny w rejestrze zgłoszeń)' : ''}}</p>
                                        <v-spacer></v-spacer>
                                        <v-btn v-if="item.adminId == loggedAdminId" @click="openEditNotesodal(item)" style="margin-left:50px;">
                                            <v-icon color="green">mdi-pencil-box-outline</v-icon><div>Edytuj</div>
                                        </v-btn>

                                    </v-card-title>
                                    <v-card-text>
                                        <div>{{item.text}}</div>
                                    </v-card-text>
                                </v-card>
                            </v-card-text>
                        </div>
                        <div v-else>
                            <v-card-text>
                                Brak notatek dotyczących działan następczych...
                            </v-card-text>
                        </div>
                        <v-card-text class="pa-4 pb-0" style="font-size:0.8em">*Działania następcze nie są widoczne dla sygnalisty</v-card-text>
                        <hr />
                        <div>
                            <v-card-title class="pa-3 pb-1">
                                <p>Działania następcze do zgłoszenia</p>

                                <v-spacer></v-spacer>
                                <v-checkbox v-model="showInReport"
                                            label="Pokazuj w rejestrze zgłoszeń"
                                            @change="$v.showInReport.$touch()"
                                            @blur="$v.showInReport.$touch()"></v-checkbox>
                            </v-card-title>
                            <div>
                                <v-card-text class="pa-3 pb-3">
                                    <v-textarea v-model="notesArea" solo auto-grow label="Treść..."></v-textarea>
                                    <div class="text-center">
                                        <v-btn @click="sendNotes()" :disabled="notesArea.length == 0">
                                            <v-icon color="orange">mdi-pencil-box-outline</v-icon><div>DODAJ</div>
                                        </v-btn>
                                    </div>
                                </v-card-text>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="showPropertyDamageInfoModal && isCustomerActive()">
                <v-col cols="12">
                    <v-card>
                        <v-card-title class="pa-6 pb-1">
                            <p>Informacje o szkodzie majątkowej</p>
                            <v-spacer></v-spacer>
                            <v-btn @click="closeModal()" style="margin-left:50px;">Ukryj</v-btn>
                        </v-card-title>
                        <div v-if="propertyDamageInfos.length > 0">
                            <v-card-text class="pa-4 pb-1" v-for="item in propertyDamageInfos" :key="item.id">
                                <v-card>
                                    <v-card-title class="pa-3 pb-0" style="margin-bottom: 15px">
                                        <p style="font-size: 0.8em;">{{item.adminName}}</p>
                                        <p style="margin-left: 15px; font-size: 0.8em;">{{formatDateToDisplay(item.date)}} {{item.showInReport == true ? '(wpis widoczny w rejestrze zgłoszeń)' : ''}}</p>
                                        <v-spacer></v-spacer>
                                        <v-btn v-if="item.adminId == loggedAdminId" @click="openEditPropertyDamageModal(item)" style="margin-left:50px;">
                                            <v-icon color="green">mdi-pencil-box-outline</v-icon><div>Edytuj</div>
                                        </v-btn>

                                    </v-card-title>
                                    <v-card-text>
                                        <div>{{item.text}}</div>
                                    </v-card-text>
                                </v-card>
                            </v-card-text>
                        </div>
                        <div v-else>
                            <v-card-text>
                                Brak notatek dotyczących szkód majątkowych...
                            </v-card-text>
                        </div>
                        <v-card-text class="pa-4 pb-0" style="font-size:0.8em">*Informacje o szkodzie majątkowej nie są widoczne dla sygnalisty</v-card-text>
                        <hr />
                        <div>
                            <v-card-title class="pa-3 pb-1">
                                <p>Informacje o szkodzie majątkowej do zgłoszenia</p>

                                <v-spacer></v-spacer>
                                <v-checkbox v-model="showInReportPropertyDamage"
                                            label="Pokazuj w rejestrze zgłoszeń"
                                            @change="$v.showInReportPropertyDamage.$touch()"
                                            @blur="$v.showInReportPropertyDamage.$touch()"></v-checkbox>
                            </v-card-title>
                            <div>
                                <v-card-text class="pa-3 pb-3">
                                    <v-textarea v-model="propertyDamageArea" solo auto-grow label="Treść..."></v-textarea>
                                    <div class="text-center">
                                        <v-btn @click="sendPropertyDamageInfo()" :disabled="propertyDamageArea.length == 0">
                                            <v-icon color="orange">mdi-pencil-box-outline</v-icon><div>DODAJ</div>
                                        </v-btn>
                                    </div>
                                </v-card-text>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>

            <EditNoteModal v-show="showNoteModal" @close="closeEditNoteModal" :noteToEdit="noteToEdit" @updateNotes="updateNotes" />
            <EditNoteModal v-show="showDemageModal" @close="closeEditDamageModal" :noteToEdit="damageToEdit" @updateNotes="updatePropertyDamageInfo" />

        </div>

    </v-container>
</template>

<script>
    import Service from '@/services/Service'
    import CryptoJS from 'crypto-js'
    import ErrorSubscription from '@/components/Modals/ErrorInfoSubscription.vue'
    import EditNoteModal from '@/components/Modals/EditNoteModal.vue'
export default {
  name: 'Signal',
  components: {
      ErrorSubscription,
      EditNoteModal
    },
  data() {
    return {
      showNoteModal: false,
      showDemageModal: false,
      showLoader: false,
      showLoaderReplyTosygnalist: false,
      signal: {},
      noteToEdit: {},
      damageToEdit: {},
      decryptSignal: false,
      hideMessages: false,
      sendMessageFlag: false,
      fileshowFlag: false,
      messages: [],
      textarea: '',
      signalObject: {},
      files: [],
      pk: '',
      pkid: 0,
      catid: 0,
      main: [],
      canArchivize: false,
      showNotesModal: false,
      showPropertyDamageInfoModal: false,
      notes: [],
      propertyDamageInfos: [],
      notesArea: '',
      propertyDamageArea: '',
      showInReport: false,
      showInReportPropertyDamage: false,
      cat: [],
      selectedCategory: {},
      loggedAdminId: 0,
      privateFiles: [],
      showPrivateFilesFlag: false,
    }
  },
async mounted() {
    try {

        if (this.isCustomerActive()) {
            this.main = (await Service.getSpecificSignal(this.$route.params.identifier)).data,
                this.signal = this.main.signal,
                this.canArchivize = this.main.canArchivize,
                this.loggedAdminId = this.main.adminId,
                this.selectedCategory = { catName: this.signal.category, catId: this.signal.categoryId },
                this.cat = this.main.categories,
                this.privateFiles = this.main.signal.privateFiles
        }

    } catch (e) {
        alert(e.response.data);
        if (e.response.status == 401)
            this.$router.push({ name: 'Login' })
        else {
            this.$router.push({ name: 'Dashboard' })
        }
    }
  },
  methods: {
      showPrivateFiles() {
          this.showPrivateFilesFlag = !this.showPrivateFilesFlag;
      },
      async updateNotes(item) {
          try {
              var result = (await Service.updateNotesForSignal(item)).data;
              if (Object.keys(result).length > 0) {
                  if (this.notes == null)
                      this.notes = [];

                  this.notes = result;
                  this.noteToEdit = {};
                  this.showNoteModal = false;
              }

          } catch (e) {
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      async updatePropertyDamageInfo(item) {
          try {
              var result = (await Service.updatePropertyDamageInfoForSignal(item)).data;
              if (Object.keys(result).length > 0) {
                  if (this.propertyDamageInfos == null)
                      this.propertyDamageInfos = [];

                  this.propertyDamageInfos = result;
                  this.damageToEdit = {};
                  this.showDemageModal = false;
              }

          } catch (e) {
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      closeEditNoteModal() {
          this.noteToEdit = {};
          this.showNoteModal = false;
      },
      closeEditDamageModal() {
          this.damageToEdit = {};
          this.showDemageModal = false;
      },
      openEditNotesodal(item) {
          this.noteToEdit = item;
          this.showNoteModal = true;
      },
      openEditPropertyDamageModal(item) {
          this.damageToEdit = item;
          this.showDemageModal = true;
      },
      isCustomerActive() {
          return this.$store.state.admin.customerIsActive;
      },
      showLogsForSignal() {
          localStorage["sygnalIdForLogs"] = JSON.stringify({ sygnalid: this.signal.id });
          this.$router.push({ name: 'Logs' });
      },
      formatDateToDisplay(value) {
          var date = new Date(value)
          var d = date.toLocaleString('default', {
              year: 'numeric',
              month: 'long',
              weekday: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
          });

          return d;
      },
      async moveSygnalToCategory(event) {
          try {
              var n = '';
              var c = this.cat.findIndex(x => x.catId == event);
              if (c != -1)
                  n = this.cat[c].catName;

              if (confirm('Czy na pewno przenieść zgłoszenie do kategorii: ' + n + '?')) {
                  var temp = {
                      catId: event,
                      catName: n,
                      messageId: this.signal.id,
                      ip: this.$store.state.ip
                  };

                  var result = (await Service.moveSygnalToNewCategory(temp)).data;
                  if (Object.keys(result).length > 0) {
                      this.selectedCategory = { catName: result.catName, catId: result.catId };
                      this.signal.category = result.catName;
                      this.signal.categoryId = result.catId;
                  }
              } else {
                  this.selectedCategory = { catName: this.signal.category, catId: this.signal.categoryId };
                  return;
              }
          } catch (e) {
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      async sendNotes() {
          try {
              var temp = {
                  messageId: this.signal.id,
                  text: this.notesArea,
                  ip: this.$store.state.ip,
                  showInReport: this.showInReport
              }

              var result = (await Service.addNotesForSignal(temp)).data;
              if (Object.keys(result).length > 0) {
                  if (this.notes == null)
                      this.notes = [];

                  this.notes.push(result);
                  this.notesArea = '';
                  this.showInReport = false;
              }
              
          } catch (e) {
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      async sendPropertyDamageInfo() {
          try {
              var temp = {
                  messageId: this.signal.id,
                  text: this.propertyDamageArea,
                  ip: this.$store.state.ip,
                  showInReport: this.showInReportPropertyDamage
              }

              var result = (await Service.addPropertyDamageInfoForSignal(temp)).data;
              if (Object.keys(result).length > 0) {
                  if (this.propertyDamageInfos == null)
                      this.propertyDamageInfos = [];

                  this.propertyDamageInfos.push(result);
                  this.propertyDamageArea = '';
                  this.showInReportPropertyDamage = false;
              }

          } catch (e) {
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      closeModal() {
          this.showNotesModal = false;
          this.showPropertyDamageInfoModal = false;
          this.notes = [];
          this.propertyDamageInfos = [];
          this.notesArea = '';
          this.propertyDamageArea = '';
          this.showInReport = false;
          this.showInReportPropertyDamage = false;
      },
      async showNotes() {
          try {
                var id = this.signal.id;
                var result = (await Service.showNotesForSignal(id)).data;
              if (Object.keys(result).length > 0) {
                  this.notes = result;
              }
              this.showNotesModal = true;
          } catch (e) {
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      async showPropertyDamageInfo() {
          try {
              var id = this.signal.id;
              var result = (await Service.showPropertyDamageInfoForSignal(id)).data;
              if (Object.keys(result).length > 0) {
                  this.propertyDamageInfos = result;
              }
              this.showPropertyDamageInfoModal = true;
          } catch (e) {
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      prepareLabelForSelect(item) {
          return item.split('|')[0];
      },
      right(str, chr) {
          return str.slice(str.length - chr, str.length);
      },
      b64decode(x) {
          var result = decodeURIComponent(escape(window.atob(x)));
          return result;
      },
      b64encode(x) {
          var result = btoa(x);
          return result;
      },
      async szyfruj() {
          try {
              this.showLoaderReplyTosygnalist = true;
              var b64 = '';

              var result = (await Service.getSygnaliPublicKey()).data;
              if ((Object.keys(result).length > 0)) {
                  this.pk = result.key;
                  this.pkid = result.id;
                  var toencrypt = this.textarea;
                  var c = CryptoJS.AES.encrypt(toencrypt, this.pk).toString();
                  var w = [{ name: "reply_encrypted", value: c }];
                  var w2 = JSON.stringify(w);
                  b64 = this.b64encode(w2);
                  setTimeout(() => { this.sendMessage(b64); }, 1000);
              }
              else {
                  alert("Błąd podczas pobierania klucza zgłoszenia");
                  this.showLoaderReplyTosygnalist = false;
              }
          } catch (e) {
              this.showLoaderReplyTosygnalist = false;
              alert(e.response.status + " - " + e.response.data.toString());
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      odszyfruj(k, s, result)
      {
          try {
              this.messages = [];
              if (k != "0") {
                  var x = this.decrypt(k, s);
                  const arr = JSON.parse(x);
                  var tempArr = [];
                  for (var i = 0; i < arr.length; i++) {
                      var tempId = arr[i].name.split("&")[0];
                      var type = parseInt(arr[i].name.split("&").pop().split("#")[0]);

                      //tymczasowo
                      if (tempId.includes("_decrypted"))
                          tempId = "Pole";

                      var temp = {
                          label: tempId,
                          value: arr[i].value,
                          type: type
                      }

                      tempArr.push(temp);
                  }
                  
                  if (this.selectedCategory.catName.toLowerCase() === "zgłoszenia zewnętrzne") {
                      var tempRequire = tempArr.find(x => x.label.includes("Żądanie wydania zaświadczenia o podleganiu ochronie (art. 38 ustawy)"));

                      if (tempRequire === undefined) {
                          var temp1 = {
                              label: "Żądanie wydania zaświadczenia o podleganiu ochronie (art. 38 ustawy)",
                              value: "off",
                              type: 3
                          };

                          tempArr.push(temp1);
                      }
                  }

                  var msg = {
                      adminName: result[0].adminName,
                      sygnalistName: result[0].sygnalistName,
                      sygnalistEmail: result[0].sygnalistEmail,
                      sygnalistPhone: result[0].sygnalistPhone,
                      datetime: result[0].datetime,
                      msg: tempArr
                  }

                  this.messages.push(msg);
              } else {
                  var temparr3 = [];
                  var text = '';
                  if (result[0].type == 2)
                      text = 'Zgłoszenie telefoniczne';
                  else if (result[0].type == 3)
                      text = 'Zgłoszenie email';
                  else
                      text = 'Zgłoszenie';

                  var ob = { label: text, type: 1, value: result[0].msg };
                  temparr3.push(ob);

                  var phoneMsg = {
                      adminName: result[0].adminName,
                      sygnalistName: result[0].sygnalistName,
                      sygnalistEmail: result[0].sygnalistEmail,
                      sygnalistPhone: result[0].sygnalistPhone,
                      datetime: result[0].datetime,
                      msg: temparr3
                  };
                  this.messages.push(phoneMsg);
              }

              // odszywrowanie reszty wiadomości 
              for (var j = 1; j < result.length; j++) {
                  var x2 = this.decrypt(result[j].pkid, result[j].msg);
                  const arr = JSON.parse(x2);
                  var temparr2 = [];
                  var obj = {
                      label: 'Odpowiedź',
                      value: arr[0].value,
                      type: 1
                  }
                  temparr2.push(obj);
                  var msg2 = {
                      adminName: result[j].adminName,
                      sygnalistName: result[j].sygnalistName,
                      sygnalistEmail: result[j].sygnalistEmail,
                      sygnalistPhone: result[j].sygnalistPhone,
                      datetime: result[j].datetime,
                      msg: temparr2
                  }
                  this.messages.push(msg2);
              }
          } catch (e) {
              console.log(e)
          }
          
      },
      decrypt(key, message) {
          var m = this.b64decode(message);
          const arr = JSON.parse(m);
          for (var item of arr) {
              var n = item.name;
              var v = item.value;
              if (this.right(n, 10) == '_encrypted' && v != '') {
                  var bytes = CryptoJS.AES.decrypt(v, key);
                  var result = bytes.toString(CryptoJS.enc.Utf8);
                  item.value = result;
                  item.name = item.name.replace("_encrypted", "_decrypted")
              }
          }
          return JSON.stringify(arr)
      },
      async addToArchiv() {
          try {
              if (confirm('Czy na pewno przenieść zgłoszenie o identyfikatorze: ' + this.signal.identifier + ' do archiwum?')) {
                  var temp = {
                      ip: this.$store.state.ip,
                      id: this.signal.id
                  }

                  var result = (await Service.addToArchive(temp));
                  if (result.status == 200) {
                      this.signal.statusForReport = "Archiwalne";
                      this.signal.status = 3;
                      this.signal.archiveTime = result.data;
                  }
              } else {
                  return;
              }
          } catch (e) {
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      async showSignal() {
          try {
              var temp = {
                  ip: this.$store.state.ip,
                  id: this.signal.id
              }

              var result = (await Service.showMessages(temp)).data;
              if (Object.keys(result).length > 0) {
                  this.decryptSignal = true;
                  this.odszyfruj(result[0].pkid, result[0].msg, result);
                  this.catid = result[0].categoryId;
                  this.signal.statusForReport = result[0].statusForReport;
                  this.signal.status = result[0].status;
              }
          } catch (e) {
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
    },
    hide() {
          this.hideMessages = true;
      },
      show() {
          this.hideMessages = false;
      },
      showSendMessageBox(){
          this.sendMessageFlag = true;
      },
      hideSendMessageBox() {
          this.textarea = '';
          this.files = [];
          this.sendMessageFlag = false;
      },
      async sendMessage(b64) {
          try {
              var temp = {
                  identifier: this.signal.identifier,
                  msg: b64,
                  pkid: parseInt(this.pkid),
                  catId: this.catid,
                  ip: this.$store.state.ip
              };

              var result = (await Service.sendReply(temp)).data;
              if (Object.keys(result).length > 0) {
                  var temparr2 = [];
                  var obj = {
                      label: 'Odpowiedź',
                      value: this.textarea,
                      type: 1
                  }
                  temparr2.push(obj);
                  var msg2 = {
                      adminName: result.adminName,
                      sygnalistName: result.sygnalistName,
                      sygnalistEmail: result.sygnalistEmail,
                      datetime: result.datetime,
                      msg: temparr2
                  }
                  this.messages.push(msg2);

                  this.textarea = '';
                  this.sendMessageFlag = false;
                  this.pk = '';
                  this.pkid = 0;
                  this.catid = 0;

                  if (this.files.length > 0) {
                      let formData = new FormData();
                      for (var i = 0; i < this.files.length; i++) {
                          let file = this.files[i][0];
                          formData.append('files[' + i + ']', file);
                      }
                      var res = (await Service.saveFileForSignalByAdmin(result.identifier, formData)).data;
                      if (Object.keys(res).length > 0) {
                          if (this.signal.files == null)
                              this.signal.files = [];

                          for (var j = 0; j < res.length; j++) {
                              this.signal.files.push(res[j]);
                          }

                          this.files = [];
                      }
                  }
              }
          } catch (e) {
              this.files = [];
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
          finally {
              this.showLoaderReplyTosygnalist = false;
          }
      },
      showFiles() {
          this.fileshowFlag = true;
      },
      hideFiles() {
          this.fileshowFlag = false;
      },
      async getFile(item) {
          try {
              var id = this.signal.identifier;
              var temp = {
                  identifier: id,
                  fileId: item.id
              };

              var result = (await Service.downloadFile(temp));

              if (result.status == 200) {
                  const contentType = result.headers['content-type'];
                  var a = document.createElement('a');
                  var blob = new Blob([result.data], { 'type': contentType });
                  a.href = window.URL.createObjectURL(blob);
                  a.download = name;
                  a.click();
              }
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString());
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      async getPrivateFile(item) {
          try {
              var id = this.signal.identifier;
              var temp = {
                  identifier: id,
                  fileId: item.id
              };

              var result = (await Service.downloadPrivateFile(temp));

              if (result.status == 200) {
                  const contentType = result.headers['content-type'];
                  var a = document.createElement('a');
                  var blob = new Blob([result.data], { 'type': contentType });
                  a.href = window.URL.createObjectURL(blob);
                  a.download = name;
                  a.click();
              }
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString());
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      checkMimeType(type) {
          if (type != "application/pdf" && type != "image/jpeg" && type != "image/jpg" &&
              type != "image/png" && type != "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
              type != "text/csv" &&
              type != "audio/mpeg" &&
              type != "audio/x-m4a" &&
              type != "application/vnd.ms-excel" &&
              type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
              type != "text/plain") {
              return false;
          } else {
              return true;
          }
      },
      addFile() {
          let input = document.createElement('input');
          input.type = 'file';
          input.accept = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.png,.jpg,.jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel, .csv, audio/mpeg, audio/x-m4a';
          input.multiple = false;
          input.click();
          input.addEventListener('change', (event) => {
              if (event.target.files[0].size <= 30000000) {
                  if (this.checkMimeType(event.target.files[0].type)) {
                      if (this.files.length > 0) {
                          for (var i = 0; i < this.files.length; i++) {

                              if (this.files[i][0].name == event.target.files[0].name) {
                                  return;
                              }
                          }

                          var tempFileSize = 0;
                          for (var j = 0; j < this.files.length; j++) {
                              tempFileSize += this.files[j][0].size;
                          }
                          tempFileSize += event.target.files[0].size;
                          if (tempFileSize >= 30000000) {
                              alert("Zbyt duży rozmiar pliku! Suma załączników nie może przekraczać 30MB");
                              return;
                          }

                          this.files.push(event.target.files);
                      }
                      else {
                          this.files.push(event.target.files);
                      }
                  }
                  else {
                      alert("Nieobsługiwany typ pliku. Pliki obsługiwane to: DOC,DOCX,PDF,JPG,JPEG,PNG,XLS,XLSX,CSV,MP3,M4A")
                  }
              }
              else
              {
                  alert("Zbyt duży rozmiar pliku! Suma załączników nie może przekraczać 30MB");
              }
          });
      },
      addPrivateFile() {
          let input = document.createElement('input');
          input.type = 'file';
          input.accept = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.png,.jpg,.jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel, .csv, audio/mpeg, audio/x-m4a';
          input.multiple = false;
          input.click();
          input.addEventListener('change', (event) => {
              if (event.target.files[0].size <= 30000000) {
                  if (this.checkMimeType(event.target.files[0].type)) {
                      this.saveElement(this.signal.identifier, event.target.files);
                  }
                  else {
                      alert("Nieobsługiwany typ pliku. Pliki obsługiwane to: DOC,DOCX,PDF,JPG,JPEG,PNG,XLS,XLSX,CSV,MP3,M4A")
                      return;
                  }
              } else {
                  alert("Zbyt duży rozmiar pliku! Suma załączników nie może przekraczać 30MB");
                  return;
              }
          });
      },
      async saveElement(id, files) {
          try {
              this.showLoader = true;
              let formData = new FormData();
              for (var i = 0; i < files.length; i++) {
                  let file = files[i];
                  formData.append('files[' + i + ']', file);
              }

              var result = (await Service.savePrivateFileForSignalByAdmin(id, formData)).data;
              if (Object.keys(result).length > 0) {
                  this.privateFiles = result;
              }
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString());

              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
          finally {
              this.showLoader = false;
          }
      },
      async deletePrivateFile(item) {
          try {
              var result = (await Service.removePrivateFile(this.signal.id, parseInt(item.id)));
              if (result.status == 200) {
                  const index = this.privateFiles.findIndex(x => x.id == item.id);
                  if (index != -1) {
                      this.privateFiles.splice(index, 1);
                  }
              }
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString());

              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      deleteFile(item) {
          if (this.files.length > 0) {
              for (var i = 0; i < this.files.length; i++) {
                  if (this.files[i][0].name == item[0].name) {
                      var index = this.files.indexOf(this.files[i])
                      this.files.splice(index, 1);
                  }
              }
          }
      }
  }
}

</script>

<style scope>

</style>
